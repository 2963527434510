@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

main {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;

  .main_wrapper {
    @include Flex(flex-start, center, column);
    position: relative;
    background-image: url("../../../assets/mainImage/olea_market_bg.png");
    background-size: cover;
    width: 100%;

    .main_btn_group {
      @include Flex(center, flex-end);
      flex-direction: column;
      position: fixed;
      top: 30vh;
      right: 40px;
      z-index: 99;
    }

    .scroll_btn {
      @include Flex(center, center);
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-bottom: 5px;
      background-color: $point700;
      cursor: pointer;

      img {
        filter: brightness(2);
      }
    }

    .scroll_btn:hover {
      background-color: $point500;
    }

    .scroll_btn .to_top_btn {
      bottom: 40px;
    }

    .scroll_btn .to_botton_btn {
      top: 100px;
    }

    .linked_btn {
      width: 45px;
      height: 45px;
      margin: 5px 0;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 45px;
        height: 45px;
      }
    }

    .nft_info_section {
      height: 900px;
    }

    .holder_section {
      height: 900px;
      @include desktop {
        margin-top: 120px;
      }
    }

    .brand_section {
      height: 800px;
    }
  }
}

// 칼리오페 민팅 관련 추가
.calliope_popup {
  position: absolute;
  margin-top: 150px;
  width: 500px;
  height: 600px;
  background-image: url("../../../assets/mint/calliope_popup_info.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 19;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;

  .calliope_popup_close {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  & > div {
    @include Flex(center, center);
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 60px;

    .calliope_popup_move {
      width: 200px;
      height: 50px;
      background-image: url("../../../assets/mint/calliope_popup_btn.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      position: absolute;
    }
  }
}
