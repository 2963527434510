@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.my_wallet_card_section_wrapper {
  @include Flex();
  width: 100%;
  height: 100%;

  .my_wallet_card_section_container {
    @include Flex(flex-start, center, column);
    width: 1200px;
    height: 100%;
    top: 0;

    .my_wallet_card_section_title_section {
      position: sticky;
      z-index: 1;
      width: 100%;
      top: 240px;
      background-color: $white;
      border-bottom: 1px solid $gray400;

      .my_wallet_title_wrapper {
        width: 100%;

        @include mobile {
          display: none;
        }

        .my_wallet_title_contents {
          @include Flex(space-evenly);
          @include tablet {
            @include Flex();
            margin: auto;
            width: 90%;
          }

          .my_wallet_title_empty_box {
            min-width: 93px;

            @include tablet {
              min-width: 200px;
              margin-right: 50px;
            }
          }

          .my_wallet_title_text_box {
            @include Flex(space-evenly);
            width: 80%;

            @include mobile {
              @include Flex(space-between, flex-start, column);
              width: 40%;
            }

            .my_wallet_title_card_text {
              @include text(15px, 400, $gray900);
              @include Flex();
              min-width: 100px;
              height: 50px;
            }
          }
        }
      }
    }

    .my_wallet_card_section_card_section {
      width: 100%;
      height: 100%;
    }

    .my_wallet_card_section_toggle_box {
      @include Flex(flex-end, center);
      width: 100%;
      padding-top: 10px;
    }
  }
}

.no_results_found_wapper {
  @include Flex(space-evenly, center, column);
  width: 100%;

  img {
    margin-top: 150px;
  }

  .no_result_text {
    font-size: 28px;
    color: $gray500;
    margin-top: 40px;
    margin-bottom: 150px;
  }
}

.my_wallet_glare_card_section {
  @include Flex(flex-start, center, row);
  margin: 80px 0;
  flex-wrap: wrap;
  width: 100%;
  min-height: 600px;
  gap: 20px;

  @media (max-width: 1199px) {
    width: 76%;
  }
}
