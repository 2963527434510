@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.market_contents_wrapper {
  @include Flex();
  width: 1200px;
  margin: auto;
  padding-bottom: 180px;
  padding-top: 100px;
  flex-flow: wrap;

  .middle_wrapper {
    @include Flex(center, center);
    width: 1200px;
  }
}
