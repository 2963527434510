@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.wrapper {
  @include Flex(center, center);
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    margin-bottom: 77px;
    font-size: 38px;
    font-weight: bold;
    color: $point800;
  }

  .nft_info {
    @include Flex(center, center);

    @keyframes scaleBounce {
      from {
        opacity: 0;
        transform: scale(0);
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    .logo_box {
      @include desktop {
        display: none;
      }
    }

    .arrows {
      @include Flex(space-between, center);
      flex-direction: column;
      height: 250px;
      margin: 0 30px;

      @include desktop {
        display: none;
      }
    }

    .nft_box {
      @include Flex(space-around, flex-start);
      flex-direction: column;
      height: 600px;

      @include desktop {
        @include Flex(center, flex-start);
        flex-direction: row;
      }

      .tier_box {
        @include Flex(center, center);
        @include desktop {
          @include Flex(flex-start, center);
          flex-direction: column;
        }
        padding: 10px;

        .text_info_box {
          @include Flex(space-between, flex-start);
          flex-direction: column;
          width: 150px;
          height: 80px;
          padding: 10px;

          @include desktop {
            @include Flex(center, center);
            flex-direction: column;
            margin-bottom: 10px;
          }
        }

        .text_info_box > p:first-child {
          font-size: 30px;
          font-weight: bold;
          color: $gray600;
        }
        .text_info_box > p:last-child {
          font-size: 18px;
          color: $point700;
          font-weight: 500;
        }
      }

      .nft_avatar_box {
        @include Flex(center, center);
        margin-left: 50px;

        @include desktop {
          @include Flex(center, center);
          flex-direction: column;
          margin-left: 0;
        }

        .nft_avatar {
          @include Flex(center, center);
          flex-direction: column;
          margin: 0 10px;

          @include desktop {
            margin: 0 0 10px 0;

            img {
              width: 200px;
              height: 200px;
            }

            .nft_tier_type {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
