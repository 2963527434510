.HowToUse {
  display: flex;
  justify-content: center;
  padding-bottom: 180px;
  padding-top: 50px;

  .how_to_use_wrapper {
    width: 1200px;
  }

  h1 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 25px;
    color: #585771;
    margin-bottom: 20px;
  }

  section {
    margin-bottom: 20px;
  }

  h3 {
    color: #585771;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;
    cursor: pointer;
  }

  h4 {
    margin-bottom: 10px;
  }

  img {
    margin: 5px;
  }

  .arrowBtn {
    padding: 10px;
    transform: rotate(0deg);
    width: 20px;
    height: 20px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8BAMAAADI0sRBAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURUdwTFBQUFJSUlFRUVFRUbIycFQAAAAEdFJOUwBZf9mHVnzsAAAAaklEQVQ4y+3SwQnAIBBE0dUKArGAHGzAkAZMpv+aBGVIwF1vue0/LQzvpCKe57F0fu4yzRcOnhGPMt88szInkEegTnMAeQY2MbmKX65jcgOTW3hwEw9u485t3PkCd14XzxaWWGQv/rW9n2oyfhOKUfcZ/AAAAABJRU5ErkJggg==)
      center center no-repeat;
    cursor: pointer;
    background-size: 30px 30px;

    &.on {
      transform: rotate(180deg);
    }
  }

  .collapse_area {
    padding: 5px;
  }

  .list-num {
    counter-reset: list-counter;
    display: flex;
    flex-direction: column;
    gap: 50px;

    li {
      position: relative;
      counter-increment: list-counter;
      list-style: none;
      margin-left: 20px;

      &::before {
        position: absolute;
        left: -20px;
        content: counter(list-counter) ") ";
        margin-right: 8px;
      }
    }
  }

  .list-mark {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
      list-style-type: circle;
      margin-left: 20px;
      &::before {
        display: none;
      }
    }
  }

  .sub {
    font-size: 14px;
  }
}
