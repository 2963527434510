.EmailAuthenticationSuccess {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  min-height: 600px;
  // background-image: url("../../../assets/images/lookup_nft_bg.png");
  background-size: cover;
  background-position: center top;
  background-image: url("../../../assets/mainImage/olea_market_bg.png");
  margin: 30px 0 100px;

  h1 {
    font-size: 40px;
  }

  .EmailAuthenticationSuccess_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 40px 100px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;

    h2 {
      font-size: 20px;
      text-align: center;
    }

    strong {
      color: #52527b;
    }

    p {
      text-align: center;
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: #e1e1e1;
    }
  }

  a {
    padding: 5px 25px;
    font-size: 16px;
    border: 1px solid #52527b;
    border-radius: 3px;
    background-color: white;
    color: #52527b;
  }
}
