@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.mobile_info_wrapper {
  @include Flex(center, center, column);
  width: 100%;
  height: 100vh;
  padding-bottom: 80px;

  & > span {
    @include text(21px, 400, $point700);
    margin-top: 40px;
    opacity: 0.7;
  }
}
