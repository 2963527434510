@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.my_wallet_search_section_wrapper {
  @include Flex();
  width: 100%;
  min-height: 100px;
  position: sticky;
  top: 140px;
  background-color: $white;
  box-shadow: 0 0 15px -10px $gray400;
  z-index: 2;

  .my_wallet_search_section_container {
    @include Flex(space-between);
    position: relative;
    width: 1200px;
    height: 100%;

    .my_wallet_search_section_form {
      @include Flex(space-between);
      width: 100%;

      .my_wallet_search_section_select {
        width: 150px;
        height: 35px;
        outline: 0 none;
        border: 1px solid $gray200;

        @include mobile {
          width: 60px;
        }
      }

      .my_wallet_search_section_input_wrap {
        position: relative;
        width: 60%;
      }
      .my_wallet_search_section_input_wrap > input {
        border: 1px solid $gray100;
        width: 100%;
        height: 35px;
        padding: 10px;
      }
    }
  }
}
