$radius: 10px;

// z-index
$indexHeader: 30;
$indexSubHeader: 20;
$indexFooter: 30;
$indexScrollBtn: 30;
$indexModal: 60;
$indexEventBtn: 70;

$invalid: #e94786;
$invalid2: #fc214f;
$valid: #507df1;

$titanColor: #55b499;
$aegisColor: #436aad;
$starterColor: #797e86;

$hoverPoint: #806fa4;
$mainBtnPoint: #b5a1c9;
$point800: #52527b;
$point700: #636190;
$point500: #807fa4;
$point300: #abaac2;
$point100: #e8e7ff;
$point50: #f5f5ff;

$clubPoint100: #c6f0eb;
$clubPoint200: #5c9c95;

$ticketPoint100: #b9d2f0;
$ticketPoint200: #4e7db7;

$oleaPoint100: #b3e5fc;
$oleaPoint200: #29b6f6;

$gray900: #222222;
$gray700: #333333;
$gray650: #4c4c4c;
$gray600: #515151;
$gray550: #666666;
$gray500: #717171;
$gray450: #888888;
$gray400: #999999;
$gray200: #d8d8d8;
$gray100: #e3e3e3;
$gray50: #eeeeee;
$gray30: #f7f8f9;

$white: #fff;
$marketBox: #f5f5f5;

$yellow600: #d4c2ae;

$primary-color: #25272b;
$secondary-color: #2c3035;
$tertiary-color: #b4b4b5;

$alert-color: #e41818;
$point-color: #3562ff;

$font-primary-color: #454b58;
$font-secondary-color: #717174;
$font-tertiary-color: #a0a0a2;

$dark-font-primary-color: #f2f3f6;
$dark-font-secondary-color: #89898b;
$disabled-color: #999;
