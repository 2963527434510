.SearchInput {
  display: flex;
  gap: 10px;

  select {
    border: 1px solid #ddd;
    height: 33px;
    padding: 0 30px 0 10px;
    appearance: none;
    background: #fff
      url(data:image/gif;base64,R0lGODlhDgAKAPMAANDQ0IuLi4iIiCAgILu7u9vb2/T09NbW1jExMSEhIWJiYvn5+aGhof///wAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjFGOUM4NTJCNkFDQTExRUJBNUE4QzhBNzY3QTdGRDZEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjFGOUM4NTJDNkFDQTExRUJBNUE4QzhBNzY3QTdGRDZEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUY5Qzg1Mjk2QUNBMTFFQkE1QThDOEE3NjdBN0ZENkQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUY5Qzg1MkE2QUNBMTFFQkE1QThDOEE3NjdBN0ZENkQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAsAAAAAA4ACgAABCawyUkrqLSYBhLDHBJIjGchyjKVn3SgKsWGKVgKMEgOtS4RMZ8vAgA7)
      no-repeat right 10px center;
  }

  input {
    border: 1px solid #ddd;
    padding: 0 30px 0 10px;
  }
}
