@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";
.gray_bg {
  background-color: $gray600;
}

.violet_bg {
  background-color: $mainBtnPoint;
}

.navy_bg {
  background-color: #2b4e7a;
}

.brown_bg {
  background-color: #77502b;
}

.market_buy_ticket_wrapper {
  @include Flex(space-between, center);
  width: 50%;
  margin: 40px 5px 0px 5px;
  height: 100px;
  padding: 0 80px;
  border-radius: 15px;

  cursor: pointer;

  & > span:nth-child(1) {
    @include text(26px, 500);

    & > img {
      filter: invert(100);
      width: 12px;
      margin-left: 10px;
    }
  }

  & > img {
    width: 150px;
  }
}

.ticket_market_buy_ticket_wrapper {
  @include Flex(space-between, center);
  position: absolute;
  width: 300px;
  height: 70px;
  top: -85px;
  transform: translateX(450px);
  padding: 0 40px;
  border-radius: 15px;

  cursor: pointer;

  & > span:nth-child(1) {
    @include text(16px, 500);
    color: #f5e499;

    & > img {
      filter: invert(100);
      width: 8px;
      margin-left: 10px;
    }
  }

  & > img {
    width: 80px;
  }
}

.joy_buy_game_money_wrapper {
  @include Flex(space-between, center);
  position: absolute;
  width: 280px;
  height: 70px;
  top: 80px;
  transform: translateX(-50px);
  padding: 0 40px;
  border-radius: 15px;

  cursor: pointer;

  & > span:nth-child(1) {
    @include text(16px, 500);
    color: #f5e499;

    & > img {
      filter: invert(100);
      width: 8px;
      margin-left: 10px;
    }
  }

  & > img {
    width: 80px;
  }
}

.buy_ticket_component {
  @include Flex(space-around, center);

  & > div:nth-child(1) {
    @include Flex(center, center, column);

    & > img {
      width: 150px;
    }

    & > span > input {
      width: 50px;
      text-align: right;
      padding-right: 3px;
      transform: translateY(-1px);
    }

    & > span > button {
      @include textNoColor(16px, 500);
      width: 25px;
      height: 25px;
      border-radius: 5px;
      background-color: $point100;
      margin: 5px;
    }
  }

  & > div:nth-child(2) {
    @include Flex(center, flex-start, column);
    & > span {
      position: absolute;
      transform: translateX(10px) translateY(-20px);
      font-size: 12px;
      background-color: $white;
      color: $gray400;
    }
    & > select {
      height: 40px;
      padding: 10px;
      border: 1px solid $point100;
      border-radius: 10px;
    }
  }

  & > button {
    @include button(60px, 35px, 10px);
  }
}
