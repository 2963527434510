.SignInV2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  min-height: 600px;
  // background-image: url("../../../assets/images/lookup_nft_bg.png");
  background-size: cover;
  background-position: center top;
  background-image: url("../../../assets/mainImage/olea_market_bg.png");
  margin: 30px 0 100px;

  h1 {
    font-size: 40px;
    letter-spacing: 5px;
  }

  .signIn_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
    background-color: white;
    padding: 40px 20px 30px 20px;
    width: 500px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;

    h2 {
      font-size: 20px;
    }

    .form_area {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      gap: 20px;
    }

    .input_area {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 100px;

      width: 100%;
      position: relative;

      label {
        width: 80px;
        position: absolute;
        left: 15px;
        font-size: 15px;
      }

      input {
        width: 100%;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        padding: 0 10px;
        height: 40px;
        font-size: 15px;
        font-weight: 400;

        &:focus {
          outline-color: #52527b;
          background: #f3f0fe;
        }
      }

      button {
        width: 100%;
        border-width: 2px;
        font-size: 18px;
        height: 40px;
        border-radius: 3px;
        margin-top: 15px;
        background-color: #52527b;
        letter-spacing: 5px;
        color: white;
      }
    }

    .btn_area {
      padding: 0 100px;
      width: 100%;

      .discord {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: #525fee;
        height: 40px;
        border-radius: 3px;
        letter-spacing: 2px;

        span {
          color: white;
        }
      }
    }

    .ul_area {
      display: flex;

      li {
        padding: 0 10px;
        border-right: 1px solid #e1e1e1;
        color: #888;
        font-size: 14px;
        cursor: pointer;

        &:last-child {
          border: none;
        }
      }
    }
  }
}
