@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.sort_container_wrapper {
  @include Flex(flex-end);
  position: absolute;
  margin-bottom: 30px;
  top: 0;
  right: 0;

  .sort_container_input_group {
    @include Flex();
    width: 290px;
    margin-left: 20px;
    height: 35px;
  }
}
