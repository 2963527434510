/* 미디어쿼리 */
@import "./_mediaQuery";
@import "./_mixin";

/* 노토산스 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");

/* 숨긴콘텐츠 */
.a11y-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  /* position이 absolute일때만 동작, 구형 browser에서 사용 */
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}
/* 본문 기본설정 */
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
/* 스타일 초기화*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
body {
  margin: 0;
  font-family: "Noto Sans kr", sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}
a {
  color: inherit;
  text-decoration: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
img {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  -ms-user-drag: none;
  -moz-user-drag: -moz-none;
  -khtml-user-drag: none;
  -webkit-user-drag: none;
}
/* ul 초기화 */
ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}
/* button 초기화 */
button {
  padding: 0;
  cursor: pointer;
  border: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Noto Sans kr", sans-serif;
}
input {
  border: none;
  font-family: "Noto Sans kr", sans-serif;
  font-weight: 500;
}
/* table 초기화 */
table {
  border-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
/* 높이 초기화 */
.init-height {
  min-height: calc(100vh - 264px);
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.formik-error-message {
  display: none;
  align-items: center;
  color: rgb(255, 91, 91);
  height: 2rem;
  justify-content: start;
  transform: translate3d(5%, 0, 0);
  font-size: 12px;
  &#error-on {
    display: flex;
    transform: translateY(6px);
  }
}

.gray-line {
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  margin: 20px;
}

#modal-root {
  textarea {
    font-size: 16px;
    font-family: "Noto Sans kr", sans-serif;
  }

  label {
    width: 80px;
    font-size: 15px;
  }

  input {
    width: 100%;
    max-width: 250px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    padding: 0 10px;
    height: 40px;
    font-size: 15px;
    font-weight: 400;

    &:focus {
      outline-color: #52527b;
      background: #f3f0fe;
    }
  }
  td,
  th {
    padding: 5px;
  }

  .divCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .memo_wrapper {
    border: 1px solid #7e79d7;
    border-radius: 5px;
    width: 510px;
    height: 250px;
  }

  .memo_textarea,
  .memo_div {
    text-align: start;
    width: 508px;
    height: 210px;
    padding: 10px;
    word-break: keep-all;
    word-wrap: break-word;
    outline: none;
    border: none;
    resize: none;
  }

  .memo_tabs {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #7e79d7;
    user-select: none;

    .memo_tab {
      width: 50%;
      cursor: pointer;
      padding: 5px;

      &.isActive {
        background-color: #7e79d7;
        color: white;
      }
    }
  }

  .memo_warming {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 13px;
    color: #d32f2f;
  }
}

@include mobile {
  .formik-error-message {
    display: none;
    align-items: center;
    color: rgb(255, 91, 91);
    height: 2rem;
    justify-content: start;
    transform: translate3d(5%, 0, 0);
    font-size: 12px;
    &#error-on {
      display: flex;
      transform: translateY(6px);
    }
  }
}

@include tablet {
  .formik-error-message {
    display: none;
    align-items: center;
    color: rgb(255, 91, 91);
    height: 2rem;
    justify-content: start;
    transform: translate3d(5%, 0, 0);
    font-size: 12px;
    &#error-on {
      display: flex;
      transform: translateY(6px);
    }
  }
}

.loading-wrap {
  @include Flex();
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #fff;
  opacity: 1;
  top: 0;
  z-index: 999;
}

.smart_wallet_logo_img {
  width: 23px;
  margin-right: 5px;
  transform: translateY(3px);
}

@include mobile {
  html {
    font-size: 13px;
  }
}

@include tablet {
  html {
    font-size: 14px;
  }
}

.text_user_type {
  @include textNoColor(20px, 500);
}

.admin_color {
  color: $point700;
}

.titan_color {
  color: $titanColor;
}

.aegis_color {
  color: $aegisColor;
}

.starter_color {
  color: $starterColor;
}

.badge_img {
  width: 50px;
}

.valid {
  color: $valid;
}

.invalid {
  color: $invalid;
}

.bg_point800 {
  background-color: #52527b;
}
.co_point800 {
  color: #52527b;
}
.bg_point700 {
  background-color: #636190;
}
.co_point700 {
  color: #636190;
}
.bg_point500 {
  background-color: #807fa4;
}
.co_point500 {
  color: #807fa4;
}
.bg_point300 {
  background-color: #abaac2;
}
.co_point300 {
  color: #abaac2;
}
.bg_point100 {
  background-color: #e8e7ff;
}
.co_point100 {
  color: #e8e7ff;
}

.bg_club200 {
  background-color: #5c9c95;
}
.co_club_200 {
  color: #5c9c95;
}
.bg_club100 {
  background-color: #c6f0eb;
}
.co_club_100 {
  color: #c6f0eb;
}

.bg_ticket200 {
  background-color: #4e7db7;
}
.co_ticket_200 {
  color: #4e7db7;
}
.bg_ticket100 {
  background-color: #b9d2f0;
}
.co_ticket_100 {
  color: #b9d2f0;
}
.confirm-page-container-header__back-button-container {
  visibility: hi;
}
