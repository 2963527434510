@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.market_item_container_wrapper {
  @include Flex(flex-start);
  width: 100%;
  padding: 0 15px 0 3px;
  flex-flow: wrap;
  margin-top: 20px;

  .product_preparation_box {
    @include Flex();
    width: 100%;

    img {
      width: 400px;
      margin: 40px 0;
    }
  }
}
