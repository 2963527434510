@import "../../styles/mixin";
@import "../../styles/variables";

.gnb_empty_box {
  width: 100%;
  height: 80px;
}
.gnb_wrapper {
  @include Flex(center, center);
  position: fixed;
  border: 1px solid $point100;
  background-color: $white;
  z-index: 20;
  width: 100%;
  height: 80px;

  .gnb_box_wrap {
    @include Flex(space-between, center);
    width: 1200px;
    height: 100%;

    .gnb_box {
      @include Flex(space-between, center);
      height: 100%;
    }

    .gnb_left {
      img {
        margin-right: 40px;
        cursor: pointer;
      }
    }

    .gnb_right {
      img {
        margin-right: 4px;
      }

      .gnb_badge {
        width: 50px;
        margin-right: 10px;
      }

      .gnb_right_button {
        @include Flex(center, center);
        @include text(18px, 500, $gray700);
        cursor: pointer;
      }
    }

    .gnb_nav_item {
      @include text(18px, 500, $gray700);
      padding: 0 30px;
      cursor: pointer;
    }
  }
}
