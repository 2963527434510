@import "../../../../styles/mixin";
@import "../../../../styles/variables";

.lookup_nft_wrapper {
  @include Flex(flex-start, center);
  flex-direction: column;
  width: 100%;
  height: 100%;

  .lookup_nft_form {
    @include Flex(center, center);
    flex-direction: row;
    width: 100%;

    select {
      width: 100px;
      height: 30px;
      padding-left: 10px;
      outline: 0 none;
      border: 1px solid $gray200;
      border-radius: 20px;
    }

    .lookup_nft_input_wrap {
      position: relative;
      width: 200px;

      .lookup_nft_search_input {
        width: 100%;
        height: 30px;
        margin-left: 10px;
        padding-left: 10px;
        outline: none;
        border: 1px solid $gray200;
        border-radius: 20px;
      }
    }
  }

  .lookup_nft_balance_box {
    @include Flex(center, center);
    flex-direction: row;
    width: 100%;
    margin-top: 40px;

    .lookup_nft_balance {
      @include Flex(space-between, center);
      font-weight: 600;
      color: $point800;
    }
  }
}
