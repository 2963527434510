@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.sign_in_wrapper {
  @include Flex(center, center);
  flex-direction: column;
  width: 100%;
  min-height: 600px;

  .sign_in_ballon {
    background: #d7c1b3;
    border-radius: 50%;
    color: #fff;
    height: 48px;
    left: -30px;
    padding: 12px 12.8px;
    position: absolute;
    top: -30px;
    width: 122px;
  }

  .sign_in_ballon::after {
    border-color: #d7c1b3 transparent transparent;
    border-style: solid;
    border-width: 10px 10px 0;
    content: "";
    left: 95px;
    position: absolute;
    top: 39px;
    transform: rotate(-25deg);
  }

  .discord_connect_btn {
    @include Flex(center, center);
    flex-direction: row;
    color: $white;
    background-color: $hoverPoint;
    width: 420px;
    height: 72px;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 8px;
    border-radius: 5px;
    margin-top: 16px;
    position: relative;
    transition: ease-in-out 300ms;

    &:focus,
    &:hover,
    &:active {
      background-color: $point800;
    }
  }
}
