@import "../../../styles/mixin";
@import "../../../styles/variables";

.connect_wallet_wrapper {
  @include Flex(center, center, column);
  margin: auto;
  width: 100%;
  padding: 150px 0 250px 0;

  & > span:nth-child(1) {
    @include text(26px, 500, $gray700);
    margin-bottom: 40px;
  }
  & > span:nth-child(2) {
    @include text(20px, 500, $gray700);
    margin-bottom: 40px;
  }

  .connect_wallet_contents {
    @include Flex(space-between, center);

    .connect_wallet_card {
      @include Flex(space-evenly, center, column);
      @include box(360px, 465px, 27px, rgba(177, 176, 199, 0.1));
      border: 1px solid rgb(177, 176, 199);
      margin: 0 30px;
      padding: 20px;
      cursor: pointer;

      & > img {
        width: 220px;
        height: 220px;
      }

      &:hover {
        background-color: rgba(177, 176, 199, 0.7);
      }

      .connect_wallet_btn {
        @include Flex(center, center);
        @include button(200px, 50px, 30px);
        margin-top: 20px;

        .connect_wallet_btn_text {
          @include text(18px, 500);
          img {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .connect_wallet_input_box {
    @include Flex(center, center);
    margin-top: 40px;

    input {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    span {
      @include text(12px, 400, $gray900);
    }
  }
}
