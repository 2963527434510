.Claim {
  width: 90%;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 50px 0;
    width: 100%;

    h2 {
      color: #52527b;
    }

    ul {
      display: flex;
      position: absolute;
      left: 0;
      top: 40px;

      li {
        padding: 0 20px;
        border-right: 1px solid #e1e1e1;
        color: #888;
        font-weight: 500;
        cursor: pointer;

        &.clicked {
          color: #52527b;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  .list_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    position: relative;
    overflow: visible;

    .SearchInput {
      position: absolute;
      top: -83px;
      right: 0;
    }

    table {
      background-color: white;
      width: 100%;
      border: 1px solid #bfbfbf;

      th {
        padding: 5px;
        background: #e8e8e8;
        border: 1px solid #bfbfbf;
      }

      td {
        padding: 5px;
        border: 1px solid #bfbfbf;
        text-align: center;

        span {
          &.cancel {
            color: #d32f2f;
          }

          &.done {
            color: #179e02;
          }
        }
      }
    }

    .pagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }

  .btn_area {
    padding: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    > button {
      padding: 1px 20px;
      border-radius: 5px;

      &.default {
        border: 2px solid #52527b;
        background-color: white;
      }

      &.point {
        border: 2px solid #52527b;
        background-color: #52527b;
        color: white;
      }
    }

    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    .deleteBtn {
      cursor: pointer;
    }
  }
}
