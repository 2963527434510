.policy {
  table {
    width: 100%;

    th {
      border-top: 2px solid #515151;
      background-color: #f2f2f2;
      padding: 5px;
    }

    td {
      text-align: center;
      border: 1px solid #eee;
      padding: 5px;
      word-break: keep-all;

      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}
