.Header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e7ff;
  background-color: white;
  margin: 0 auto;
  width: 100%;
  padding: 0 5%;
  height: 80px;
  user-select: none;
  z-index: 29;

  .gnb {
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;

      font-size: 25px;
      color: #585771;
      cursor: pointer;
      margin-right: 40px;
    }

    .gnb_item {
      padding: 0 10px;
      cursor: pointer;
    }

    strong {
      padding: 0 10px;
      color: #585771;
    }
  }

  .logout {
    cursor: pointer;
  }
}
