.Admin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 250px;
    height: 100px;

    font-size: 20px;
    color: white;
    background-color: #52527b;
    border-radius: 10px;

    cursor: pointer;
  }
}
