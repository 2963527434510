@import "./variables";

@mixin font($size, $color: $font-primary-color, $weight: 500) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
}
@mixin font-normal {
  font-size: 1.5rem;
  color: $font-primary-color;
}
@mixin text-button {
  box-sizing: border-box;
  border: 2px solid $point-color;
  background-color: transparent;
  font-weight: 500;
  color: $point-color;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 50px;
  outline: 0;
  transition: all 0.3s;

  &:hover {
    background-color: $point-color;
    color: #fff;
  }
}
@mixin modal-button($border-color: #fff, $font-color: #fff) {
  box-sizing: border-box;
  border: 2px solid $border-color;
  background-color: transparent;
  font-weight: 500;
  color: $font-color;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 50px;
  outline: 0;
  transition: all 0.3s;
  padding: 22px 76px;
  margin: 50px 15px 0px 15px;

  &:hover {
    border-color: #fff;
    background-color: #fff;
    color: #2c3035;
  }
}
@mixin disable-button {
  box-sizing: border-box;
  border: 2px solid $tertiary-color;
  background-color: $tertiary-color;
  font-weight: 500;
  color: $font-primary-color;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 50px;
  outline: 0;
  transition: all 0.3s;
}
@mixin thumbnail($color: transparent, $position: center) {
  background-color: $color;
  background-position: $position;
  background-size: cover;
  background-repeat: no-repeat;
}

$checkboxColor: #b4b4b5;
$labelColor: #454b58;

@mixin wrapSet {
  background: #f2f3f6;
}

@mixin titleFontSet($size: 2.4rem, $weight: normal) {
  color: $labelColor;
  font-size: $size;
  font-weight: $weight;
}
@mixin contentFontSet {
  color: #717174;
}
@mixin placeholderSet {
  color: #a0a0a2;
}

@mixin BgSet($path, $size, $pos: center) {
  background: url($path);
  background-size: $size;
  background-position: $pos;
  background-repeat: no-repeat;
}
@mixin Flex($justify: center, $align: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}
@mixin Grid($gap, $auto, $columns, $rows, $aria) {
  display: grid;
  grid-gap: $gap;
  grid-auto-flow: $auto;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
  grid-area: $aria;
}
@mixin GridChild($column, $row) {
  grid-column: $column;
  grid-row: $row;
}
@mixin VirtualFill($pos: absolute) {
  content: "";
  position: $pos;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@mixin Virtual($pos: absolute) {
  content: "";
  position: $pos;
}
@mixin Size($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
}
@mixin Anim($duration, $timing, $delay: 0) {
  $name: anim-#{unique-id()};

  @keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing;
  animation-delay: $delay;
}

@mixin LinearGradient($deg, $color...) {
  background: linear-gradient($deg, $color);
}

@mixin button(
  $w: 200px,
  $h: 50px,
  $r: 5px,
  $c: $white,
  $bg: $point800,
  $hover: $hoverPoint
) {
  width: $w;
  height: $h;
  border-radius: $r;
  color: $c;
  background-color: $bg;
  &:hover {
    background-color: $hover;
  }
}

@mixin textNoColor($fs: 14px, $fw: 400) {
  font-size: $fs;
  font-weight: $fw;
}

@mixin text($fs: 16px, $fw: 400, $fc: $white) {
  font-size: $fs;
  font-weight: $fw;
  color: $fc;
}

@mixin box($w: 100%, $h: 100%, $br: 0, $bg: transparent) {
  width: $w;
  height: $h;
  border-radius: $br;
  background-color: $bg;
}
