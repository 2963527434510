@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.my_page_template_wrapper {
  @include Flex(space-between, center, column);
  // padding: 80px 0;

  td,
  th {
    padding: 5px;
    word-break: keep-all;
    white-space: nowrap;
  }

  .my_page_template_content {
    @include Flex(space-between, flex-start, column);
    width: 1200px;
    padding: 40px 80px;
    border-bottom: 1px solid $gray200;

    &:last-child {
      border-bottom: none;
    }

    .btn {
      margin: 0;

      span {
        &.cancel {
          color: #d32f2f;
        }

        &.done {
          color: #179e02;
        }
      }
    }

    ul.olea_point_log_tab_area {
      display: flex;
      width: 100%;
      margin-bottom: 5px;

      li {
        flex: 1;
        @include Flex(center, center);
        cursor: pointer;
        padding: 5px;
        user-select: none;
        border: 1px solid #7e79d7;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &.isActive {
          background-color: #7e79d7;
          color: white;
        }
      }
    }

    .pagination {
      @include Flex(center, center);
      width: 100%;
      margin-top: 20px;
    }
  }

  .my_page_template_content:first-child {
    @include Flex(space-between, flex-end);

    .my_page_template_box {
      height: 100px;
    }

    & > .my_page_template_box:nth-child(1) {
      @include Flex(space-between, flex-start, column);

      & > p:nth-child(1) {
        margin-bottom: 20px;
      }

      & > p:nth-child(2) {
        margin-bottom: 8px;
      }

      & > p:nth-child(3) {
        margin-bottom: 4px;

        & > button {
          @include button(90px, 20px, 5px);
          margin-left: 10px;
        }
      }

      & > p:nth-child(1) > span {
        @include text(30px, 500, $gray600);
      }

      // & > p:nth-child(2) {
      //   @include textNoColor(24px, 500);
      //   @include Flex(space-between, center);
      //   min-width: 110px;
      // }

      & > p:nth-child(2) > span {
        @include text(20px, 500, $gray400);
      }
    }

    & > .my_page_template_box:nth-child(2) {
      @include Flex(space-between, flex-end, column);

      & > p {
        @include text(24px, 500, $gray600);
      }
    }
  }

  .my_page_template_content:nth-child(2) {
    & > .my_page_template_box {
      @include Flex(space-between, center);
      width: 100%;
      gap: 16px;
    }
    & > .my_page_template_box > div {
      @include Flex(center, center, column);
      @include box(300px, 100px, 15px, $point50);
      padding: 30px;
      margin: auto;
      flex: 1;

      & > span:nth-child(1) {
        @include text(16px, 400, $gray700);
      }

      & > span:nth-child(2) {
        @include text(35px, 500, $gray650);
      }

      & > span > span {
        @include text(18px, 500, $gray650);
      }
    }
  }
}

.my_page_template_withdrawal_btn {
  @include button(150px, 30px, 5px, $white, $invalid, $invalid2);
  @include Flex(center, center);
  opacity: 0.6;
  cursor: pointer;

  &.purple {
    background-color: $point-color;
    opacity: 0.8;
    &:hover {
      background-color: $point-color;
      opacity: 1;
    }
  }
}
