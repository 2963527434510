@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.market_left_container_wrapper {
  @include box(740px, 378px, 20px, $marketBox);
  @include Flex(center, center, column);
  position: relative;
  padding: 20px;
  align-self: flex-start;

  & > img {
    position: absolute;
    top: -58px;
    left: 30px;
  }

  .market_left_container_header {
    @include Flex();
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $gray400;

    span {
      @include text(18px, 500, $gray900);
    }

    span:nth-child(1) {
      width: 162px;
      margin-right: 227px;
      margin-left: 17px;
    }
    span:nth-child(2) {
      width: 105px;
      margin-right: 43px;
    }
    span:nth-child(3) {
      width: 98px;
    }
  }

  .market_left_container_shopping_cart_container {
    @include Flex(flex-start, flex-start);
    @include text(15px, 400, $gray900);
    flex-flow: column;
    height: 200px;
    overflow: overlay;

    li {
      @include Flex();
      width: 100%;
      margin-top: 20px;
      padding-left: 17px;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      span:nth-child(1) {
        width: 400px;
      }
      span:nth-child(2) {
        width: 148px;
      }
      span:nth-child(3) {
        width: 98px;
      }
    }

    .market_left_container_minus_btn {
      @include Flex();
      background-color: unset;
    }
  }

  .market_left_container_shopping_cart_result_container {
    @include Flex();
    margin-top: 40px;

    span:nth-child(1) {
      @include text(20px, 500, $gray900);
    }
    span:nth-child(2) {
      @include text(20px, 700, $valid);
      margin-left: 18px;

      &.none {
        color: $invalid;
      }
    }
  }

  .ticket_market_left_container_shopping_cart_container {
    @include Flex(flex-start, flex-start);
    @include text(15px, 400, $gray900);
    flex-flow: column;
    height: 200px;
    overflow: overlay;
    width: 100%;
    border-bottom: 1px solid $gray400;

    li {
      @include Flex(space-between);
      width: 100%;
      margin-top: 20px;
      padding-left: 17px;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      span:nth-child(1) {
        width: 400px;
      }
      span:nth-child(2) {
        width: 148px;
      }
      span:nth-child(3) {
        width: 98px;
      }
    }

    .ticket_market_left_container_minus_btn {
      @include Flex();
      background-color: unset;
    }
  }

  .ticket_market_left_container_shopping_cart_result_container {
    @include Flex(space-between);

    width: 100%;
    margin-top: 15px;
    .ticket_market_left_container_shopping_cart_result_container_flex_Box {
      @include Flex(between);

      span:nth-child(1) {
        @include text(20px, 500, $gray900);
      }
      span:nth-child(2) {
        @include text(20px, 700, $valid);
        margin-left: 18px;
      }

      &.none {
        color: $invalid;
      }
    }
  }

  .ticket_market_left_container_buy_button {
    padding: 11px 20px;
    width: 133px;
    height: 50px;
    background-color: $ticketPoint200;
    color: $white;
    border-radius: 100px;
  }
}

.ticket_market_left_container_wrapper {
  @include box(1200px, 378px, 20px, $marketBox);
  @include Flex(center, center, column);
  position: relative;
  padding: 20px;
  align-self: flex-start;

  & > img {
    position: absolute;
    top: -58px;
    left: 30px;
  }

  .ticket_market_left_container_header {
    @include Flex(space-between);
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $gray400;

    span {
      @include text(18px, 500, $gray900);
    }

    span:nth-child(1) {
      width: 162px;
      margin-right: 227px;
      margin-left: 17px;
    }
    span:nth-child(2) {
      width: 105px;
      margin-right: 43px;
    }
    span:nth-child(3) {
      width: 98px;
    }
  }

  .ticket_market_left_container_shopping_cart_container {
    @include Flex(flex-start, flex-start);
    @include text(15px, 400, $gray900);
    flex-flow: column;
    height: 200px;
    overflow: overlay;
    width: 100%;
    border-bottom: 1px solid $gray400;

    li {
      @include Flex(space-between);
      width: 100%;
      margin-top: 20px;
      padding-left: 17px;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      span:nth-child(1) {
        width: 400px;
      }
      span:nth-child(2) {
        width: 148px;
      }
      span:nth-child(3) {
        width: 98px;
      }
    }

    .ticket_market_left_container_minus_btn {
      @include Flex();
      background-color: unset;
    }
  }

  .ticket_market_left_container_shopping_cart_result_container {
    @include Flex(space-between);

    width: 100%;
    margin-top: 15px;
    .ticket_market_left_container_shopping_cart_result_container_flex_Box {
      @include Flex(between);

      span:nth-child(1) {
        @include text(20px, 500, $gray900);
      }
      span:nth-child(2) {
        @include text(20px, 700, $valid);
        margin-left: 18px;
        &.none {
          color: $invalid;
        }
      }
    }
  }

  .ticket_market_left_container_buy_button {
    padding: 11px 20px;
    width: 133px;
    height: 50px;
    background-color: $ticketPoint200;
    color: $white;
    border-radius: 100px;
    font-size: 16px;
  }
}

.olea_market_left_container_wrapper {
  @include box(740px, 378px, 20px, #595771);
  @include Flex(flex-start, center, column);
  position: relative;
  padding: 20px;
  align-self: flex-start;
  transition: 0.3s;

  .olea_market_left_container_btn_box {
    @include Flex();
    position: absolute;
    padding-bottom: 12px;
    width: 100%;
    bottom: 0;
    left: 0;

    button {
      margin: 0 5px;
      margin-left: 12px;
      padding: 11px 20px;
      width: 133px;
      height: 50px;
      color: $white;
      border-radius: 100px;
      background-color: $point-color;
      font-size: 16px;
    }
  }

  & > img {
    position: absolute;
    top: -58px;
    left: 30px;
  }

  .market_left_container_header {
    @include Flex();
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #fff;

    span {
      @include text(18px, 500, #fff);
    }

    span:nth-child(1) {
      width: 162px;
      margin-right: 227px;
      margin-left: 17px;
    }
    span:nth-child(2) {
      width: 105px;
      margin-right: 43px;
    }
    span:nth-child(3) {
      width: 98px;
    }
  }

  .market_left_container_shopping_cart_container {
    @include Flex(flex-start, flex-start);
    @include text(15px, 400, #fff);
    width: 100%;
    flex-flow: column;
    height: 200px;
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #808080;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f0f0f0;
      &:hover {
        background-color: #cbcbcb;
      }
    }

    li {
      @include Flex();
      width: 100%;
      margin-top: 20px;
      padding-left: 17px;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      span:nth-child(1) {
        width: 400px;
      }
      span:nth-child(2) {
        width: 148px;
      }
      span:nth-child(3) {
        width: 98px;
      }
    }

    .market_left_container_minus_btn {
      @include Flex();
      background-color: unset;
    }
  }

  .market_left_container_shopping_cart_result_container {
    @include Flex();
    margin-top: 40px;

    span:nth-child(1) {
      @include text(20px, 500, #fff);
    }
    span:nth-child(2) {
      @include text(20px, 700, $valid);
      margin-left: 18px;

      &.none {
        color: $invalid;
      }
    }
  }
}
