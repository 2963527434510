@import "../../styles/variables";

.pop_layer .in_pop .in .input_box {
  margin-top: 10px;
  position: relative;
  padding-left: 100px;
}

.pop_layer .in_pop .in .input_box label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  display: block;
  height: 40px;
  line-height: 38px;
  text-align: left;
}

.pop_layer .in_pop .in .input_box:first-child {
  margin-top: 0;
}

.pop_layer .in_pop .in .input_box textarea {
  height: 150px;
  margin-top: 10px;
}

.pop_layer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.pop_layer .dim {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
}

.pop_layer .in_pop {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-top: -1500px;
  max-height: 90vh;
}

.pop_layer .in_pop .in .pp-box {
  p {
    color: #7e79d7;
    font-weight: 500;
    font-size: 16px;
  }
}

.pop_layer .in_pop.active {
  margin-top: 0;
}

.pop_layer .in_pop.sm {
  width: 550px;
}

.pop_layer .in_pop.md {
  width: 880px;
}

.pop_layer .in_pop.lg {
  width: 1000px;
}

.pop_layer .in_pop h3.tit {
  color: #222;
  font-size: 18px;
  padding: 17px 15px;
  background: #e8e7ff;
  border-radius: 3px 3px 0 0;
  font-weight: 400;
  line-height: 18px;
  font-weight: 500;
}

.pop_layer .in_pop h4.in_tit {
  font-size: 18px;
  line-height: 26px;
  margin: 20px 0 10px;
  font-weight: 600;
  position: relative;
}

.pop_layer .in_pop h4.in_tit span {
  position: absolute;
  right: 0;
  top: 5px;
  color: #5954c0;
  font-size: 14px;
  line-height: 16px;
}

.pop_layer .in_pop h4.in_tit:first-child {
  margin-top: 0;
}

.pop_layer .in_pop .in {
  padding: 30px 20px;
  border-radius: 0 0 3px 3px;
  background: #fff;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  margin-top: -1px;
}

.pop_layer .in_pop .in.scroll_none {
  max-height: inherit;
  overflow: visible;
}

.pop_layer .in_pop .in.scroll_none .check_style {
  margin-left: 0;
  margin-top: 10px;
}

.pop_layer .in_pop .in .etc_txt {
  border: 2px solid #ddd;
  word-break: keep-all;
  max-height: 600px;
  min-height: 400px;
}

.pop_layer .in_pop .in .etc_txt .nano-content {
  padding: 15px;
}

.pop_layer .in_pop .in.center {
  text-align: center;
  position: relative;
}

.pop_layer .in_pop .in .ico {
  font-size: 0;
  margin: 20px 0;
  display: block;
}

.pop_layer .in_pop .in p {
  font-size: 14px;
  color: #515151;
}

.pop_layer .in_pop .in p .point {
  font-size: 16px;
}

.pop_layer .in_pop .in .ico img {
  display: inline-block;
  width: 80px;
}

.pop_layer .in_pop .in .txt {
  font-size: 15px;
  line-height: 22px;
  word-break: keep-all;
}

.pop_layer .in_pop .in p.color_b {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
}

.pop_layer .in_pop .middle_txt {
  text-align: center;
  padding-bottom: 20px;
}

.pop_layer .in_pop .middle_txt strong {
  display: block;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 5px;
}

.pop_layer .in_pop .middle_txt p {
  font-size: 14px;
  line-height: 20px;
}

.pop_layer .in_pop .middle_txt + .btn_area {
  padding-top: 0;
}

.pop_layer .in_pop td .btn {
  min-width: 70px;
}

.pop_layer .tab_area .tab li {
  width: 130px;
}

.pop_layer .in_pop button.close {
  position: absolute;
  right: 13px;
  top: 12px;
  width: 26px;
  height: 26px;
  // background: url(../img/common/btn_close_w.png) center center no-repeat;
  line-height: 300px;
  overflow: hidden;
  background-size: 16px 16px;
  padding: 5px;
  transform: rotate(45deg);
  background-color: transparent;
}

.pop_layer .in_pop button.close:before {
  content: "";
  display: block;
  border-left: 1px solid #515151;
  height: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pop_layer .in_pop button.close:after {
  content: "";
  display: block;
  border-top: 1px solid #515151;
  width: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pop_layer .in_pop .empty {
  text-align: center;
  padding: 30px 0 20px;
}

@media screen and (max-width: 1240px) {
  .pop_layer .in_pop .in {
    margin: 0;
  }
}

@media screen and (max-width: 1060px) {
  .pop_layer .in_pop {
    left: 10px;
    right: 10px;
    transform: translate(0, -50%);
    max-height: calc(90vh - 42px);
  }

  .pop_layer .in_pop.md,
  .pop_layer .in_pop.lg {
    width: calc(100% - 20px);
  }

  .pop_layer .in_pop.sm {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pop_layer .in_pop .in .etc_txt {
    max-height: calc(90vh - 142px);
  }
}

.btn_area {
  padding: 20px 0 0 0;
}

.btn {
  padding: 5px 20px;
  margin: 5px;
  border-radius: 3px;
  color: $white;
  background-color: #7e79d7;
}
