.Tools {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 20px;

  .icon_area {
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 2px solid #52527b;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }

  .tool_wrapper {
    width: 350px;
    border: 1px solid #52527b;
    border-radius: 5px;
    background-color: white;

    .tool_header {
      background-color: #52527b;
      color: white;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .btn_close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
        padding: 10px;
      }
    }

    .tool_body {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .btn_change {
        text-align: center;
        user-select: none;
        margin: 10px;
        cursor: pointer;
      }

      .content_area {
        width: 100%;
        display: flex;
        border: 1px solid #52527b;
        border-radius: 5px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input,
        .result_area {
          flex: 1;
          padding: 5px 0 5px 10px;
          outline: none;
          text-align: right;
        }

        .name_area {
          text-align: center;
          width: 50px;
          padding: 5px;
          background-color: #52527b;
          color: white;
        }

        .unit_area {
          padding: 5px;
          text-align: center;
          width: 50px;
        }
      }
    }
  }
}
