@import "../../styles/mixin";
@import "../../styles/variables";

.footer_wrapper {
  @include Flex(center, center);
  width: 100%;
  padding: 20px;
  border-top: 1px solid $gray200;

  .footer_inner_wrapper {
    @include Flex(space-between, center);
    margin: auto;
    height: 100%;
    width: 1200px;
    padding: 10px;

    .footer_inner_wrapper_left {
      .graytone_text {
        color: $gray400;
        margin: 10px;
      }
    }

    .footer_inner_wrapper_right {
      height: 180px;
      .footer_inner_wrapper_right_content {
        @include Flex(space-between, center);

        .clickable_text {
          margin: 0 20px;
          color: $gray400;
          cursor: pointer;
        }

        .clickable_text:hover {
          color: $point700;
        }
      }
    }
  }
}
