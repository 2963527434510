@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.market_token_modal {
  .point_btn {
    padding: 8px;
    border-radius: 5px;
    background: #3562ff;
    color: #fff;
  }
}

.market_right_container_wrapper {
  @include box(450px, 378px, 20px, $marketBox);
  margin-left: 10px;
  padding: 20px;
  align-self: flex-start;

  .market_right_container_header {
    @include Flex();
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $gray400;

    span {
      @include text(18px, 500, $gray900);
      width: 162px;
      margin-right: 244px;
    }
  }

  .market_right_container_nft_container {
    @include Flex(flex-start);
    width: 100%;
    padding: 0 20px;
    flex-flow: column;
    height: 202px;
    overflow: overlay;

    li {
      @include Flex(flex-start);
      width: 333px;
      min-height: 55px;
      margin-top: 11px;
      background-color: $white;
      border: 0.5px solid #edecf7;
      border-radius: 4px;

      & > img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      &.active {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &:focus {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &:hover {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &:active {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &.none {
        border: 0.5px solid $gray400;
        background: $gray200;
        color: $gray400;
      }

      & > .market_right_container_nft_name {
        @include text(14px, 500, $gray900);
        display: inline-block;
        width: 140px;
      }
      & > .market_right_container_nft_balance {
        @include text(13px, 400, $gray900);
        display: inline-block;
        width: 67px;
        margin-right: 32px;
      }
    }
  }

  .market_right_container_btn_box {
    @include Flex();
    margin-top: 30px;

    button {
      padding: 11px 20px;
      width: 133px;
      height: 50px;
      background-color: $point700;
      color: $white;
      border-radius: 100px;
      font-size: 16px;
    }
  }

  .club_market_right_container_nft_container {
    @include Flex(space-between, center);
    width: 100%;
    padding: 0 20px;
    height: 202px;
    overflow: hidden;

    li {
      @include Flex(center, center, column);
      width: 180px;
      height: 180px;
      margin-top: 11px;
      background-color: $white;
      border: 0.5px solid #edecf7;
      border-radius: 10px;

      .market_right_container_img_box {
        @include Flex(center, center);
        width: 100px;
        height: 100px;
      }

      .market_right_container_voucher_img {
        width: 100px;
        pointer-events: none;
      }

      .market_right_container_club_img {
        width: 100px;
        height: 100px;
        pointer-events: none;
      }

      &.active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:focus {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:hover {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &.none {
        border: 0.5px solid $gray400;
        background: $gray200;
        color: $gray400;
      }

      & > .market_right_container_nft_name {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
      & > .market_right_container_nft_balance {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
    }
  }

  .olea_market_right_container_nft_container {
    @include Flex(space-between, flex-start);
    width: 100%;
    padding: 0 20px;
    height: 202px;
    overflow: hidden;

    li {
      @include Flex(center, center, column);
      width: 180px;
      height: 180px;
      margin-top: 11px;
      background-color: $white;
      border: 0.5px solid #edecf7;
      border-radius: 10px;

      .market_right_container_img_box {
        @include Flex(center, center);
        width: 100px;
        height: 100px;
      }

      .market_right_container_voucher_img {
        width: 100px;
        pointer-events: none;
      }

      .market_right_container_club_img {
        width: 100px;
        height: 100px;
        pointer-events: none;
      }

      &.active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:focus {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:hover {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &.none {
        border: 0.5px solid $gray400;
        background: $gray200;
        color: $gray400;
      }

      & > .market_right_container_nft_name {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
      & > .market_right_container_nft_balance {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
    }
  }

  .club_market_right_container_btn_box {
    @include Flex();
    margin-top: 30px;

    button {
      padding: 11px 20px;
      width: 133px;
      height: 50px;
      color: $white;
      border-radius: 100px;
      background-color: $clubPoint200;
      font-size: 16px;
    }
  }

  .olea_market_right_container_btn_box {
    @include Flex();
    margin-top: 30px;

    button {
      padding: 11px 20px;
      width: 133px;
      height: 50px;
      color: $white;
      border-radius: 100px;
      background-color: $oleaPoint200;
      font-size: 16px;
    }
  }
}

.olea_market_right_container_wrapper {
  @include box(450px, 378px, 20px, #595771);
  margin-left: 10px;
  padding: 20px;
  align-self: flex-start;
  position: relative;

  .market_right_container_point_container {
    @include Flex();
    flex-wrap: wrap;
    position: absolute;
    top: -40px;
    right: 16px;
    gap: 10px;

    button {
      padding: 8px;
      border-radius: 5px;
      background: #3562ff;
      color: #fff;
    }
  }

  .flex_start {
    height: 202px;
    overflow: auto;
    padding: 0 20px;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #808080;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f0f0f0;
      &:hover {
        background-color: #cbcbcb;
      }
    }
  }

  .point_box {
    position: relative;
    margin-top: 20px;
    @include Flex(space-between, center);
    border: 1px solid $gray200;
    padding: 12px;
    border-radius: 10px;
    overflow: hidden;

    input[type="radio"] {
      cursor: pointer;
    }

    &:hover,
    &:has(input[type="radio"]:checked) {
      border: 0.5px solid $clubPoint200;
      background: #ffffff;
      color: #333;
    }
  }

  .market_right_container_header {
    @include Flex(space-between, center);
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #fff;

    > div {
      @include text(18px, 500, #fff);

      > div {
        text-align: end;
        @include text(14px, 500, #fff);
      }
    }
  }

  .market_right_container_nft_container {
    @include Flex(flex-start);
    width: 100%;
    padding: 0 20px;
    flex-flow: column;
    height: 202px;
    overflow: overlay;

    li {
      @include Flex(flex-start);
      width: 333px;
      min-height: 55px;
      margin-top: 11px;
      background-color: $white;
      border: 0.5px solid #edecf7;
      border-radius: 4px;

      & > img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      &.active {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &:focus {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &:hover {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &:active {
        border: 0.5px solid #595771;
        background: #edecf6;
      }
      &.none {
        border: 0.5px solid $gray400;
        background: $gray200;
        color: $gray400;
      }

      & > .market_right_container_nft_name {
        @include text(14px, 500, $gray900);
        display: inline-block;
        width: 140px;
      }
      & > .market_right_container_nft_balance {
        @include text(13px, 400, $gray900);
        display: inline-block;
        width: 67px;
        margin-right: 32px;
      }
    }
  }

  .market_right_container_btn_box {
    @include Flex();
    margin-top: 30px;

    button {
      padding: 11px 20px;
      width: 133px;
      height: 50px;
      background-color: $point700;
      color: $white;
      border-radius: 100px;
      font-size: 16px;
    }
  }

  .club_market_right_container_nft_container {
    @include Flex(space-between, center);
    width: 100%;
    padding: 0 20px;
    height: 202px;
    overflow: hidden;

    li {
      @include Flex(center, center, column);
      width: 180px;
      height: 180px;
      margin-top: 11px;
      background-color: $white;
      border: 0.5px solid #edecf7;
      border-radius: 10px;

      .market_right_container_img_box {
        @include Flex(center, center);
        width: 100px;
        height: 100px;
      }

      .market_right_container_voucher_img {
        width: 100px;
        pointer-events: none;
      }

      .market_right_container_club_img {
        width: 100px;
        height: 100px;
        pointer-events: none;
      }

      &.active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:focus {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:hover {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &.none {
        border: 0.5px solid $gray400;
        background: $gray200;
        color: $gray400;
      }

      & > .market_right_container_nft_name {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
      & > .market_right_container_nft_balance {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
    }
  }

  .olea_market_right_container_nft_container {
    @include Flex(space-between, flex-start);
    width: 100%;
    height: 230px;
    overflow: hidden;

    .olea_payment_log_container {
      width: 100%;
      padding-top: 5px;
    }

    li {
      @include Flex(center, center, column);
      width: 180px;
      height: 180px;
      margin-top: 11px;
      background-color: $white;
      border: 0.5px solid #edecf7;
      border-radius: 10px;

      .market_right_container_img_box {
        @include Flex(center, center);
        width: 100px;
        height: 100px;
      }

      .market_right_container_voucher_img {
        width: 100px;
        pointer-events: none;
      }

      .market_right_container_club_img {
        width: 100px;
        height: 100px;
        pointer-events: none;
      }

      &.active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:focus {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:hover {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &:active {
        border: 0.5px solid $clubPoint200;
        background: rgba(92, 156, 148, 0.3);
      }
      &.none {
        border: 0.5px solid $gray400;
        background: $gray200;
        color: $gray400;
      }

      & > .market_right_container_nft_name {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
      & > .market_right_container_nft_balance {
        @include text(16px, 500, $gray900);
        display: inline-block;
      }
    }
  }

  .club_market_right_container_btn_box {
    @include Flex();
    margin-top: 30px;

    button {
      padding: 11px 20px;
      width: 133px;
      height: 50px;
      color: $white;
      border-radius: 100px;
      background-color: $clubPoint200;
      font-size: 16px;
    }
  }

  .olea_market_right_container_btn_box {
    @include Flex();
    position: absolute;
    padding-bottom: 12px;
    width: 100%;
    bottom: 0;
    left: 0;

    button {
      margin: 0 5px;
      padding: 11px 20px;
      width: 133px;
      height: 50px;
      color: $white;
      border-radius: 100px;
      background-color: $point-color;
      font-size: 16px;
      &:disabled {
        color: $gray500;
        background-color: $gray100;
        cursor: inherit;
      }
    }
  }
}
