@import "../../../../styles/mixin";
@import "../../../../styles/variables";

.holders_wrapper {
  @include Flex(center, center);
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  .holders_text {
    padding-top: 60px;
    color: $gray600;
    font-size: 30px;
    font-weight: 500;
  }

  .holders_btn_box {
    margin-top: 50px;
    bottom: -220px;

    .holders_btn {
      @include Flex(center, center);
      flex-direction: row;
      margin-bottom: 20px;
      width: 360px;
      height: 80px;
      background-color: $point700;
      border-radius: 50px;

      img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }

      .holder_textbox {
        transform: translateX(-10px);
      }

      .holders_btn_text {
        color: $white;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}
