@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.inquire_wrapper {
  @include Flex(center, center, column);
  width: 100%;
  padding-top: 100px;
}

.inquire_style_wrapper {
  @include Flex();
  width: 100%;
  height: 100%;
  padding: 40px;
}

.inquire_style_container {
  @include Flex(flex-start, center, column);
  width: 850px;
  height: 100%;
}

.inquire_style_image_box {
  @include Flex();
  width: 100%;
  margin-top: 20px;
}

.inquire_style_title_text {
  @include text(44px, 600, $point700);
  text-align: center;
  margin-top: 30px;
}

.inquire_style_form_wrapper {
  width: 800px;
  margin: top 40px;
}

.inquire_style_form_box {
  width: 100%;
}
.inquire_style_input_box {
  @include Flex(flex-start, flex-start);
  width: 100%;
  margin-bottom: 10px;
}

.inquire_style_top_input_label {
  width: 100px;
  height: 100%;
  margin-top: 8px;
  transform: translateX(-20px);
  text-align: right;
}

.inquire_style_top_input_label_text {
  @include text(22px, 500, $point700);
}

.inquire_style_top_input_wrap {
  margin-right: 10px;
  width: 80%;
}

.inquire_style_input {
  min-width: 233px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 5px;
  border: 1px solid $gray50;
}

.inquire_style_text_area {
  @include text(14px, 400, $gray900);
  width: 100%;
  resize: none;
  overflow-y: visible;
  border: 1px solid $gray50;
  margin-top: 8px;
  border-radius: 5px;
  padding: 5px;
}

.inquire_style_input_alert {
  @include text(15px, 500, $point300);
  position: absolute;
  margin-top: 5px;
}

.inquire_style_send_email_btn {
  @include Flex();
  background-color: $point700;
  color: $white;
  transition: ease-in-out 300ms;
  width: 150px;
  height: 40px;
  margin: auto;
  cursor: pointer;

  &:hover {
    color: $gray900;
    background-color: $point100;
    box-shadow: 0 0 0 1px $point100 inset;
  }
}
