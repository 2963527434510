@import "../../../styles/mixin";
@import "../../../styles/variables";
@import "../../../styles/mediaQuery";

.market_render_wrapper {
  width: 220px;
  height: 330px;
  background-color: $gray50;
  border-radius: 20px;
  margin-left: 15px;
  margin-bottom: 16px;
  transform-style: preserve-3d;

  &:hover {
    box-shadow: 0 0 10px 0 #c7c7c7;
    .market_render_item_img_box {
      transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: 0 0 10px 0px #c7c7c7;
      transform: translateZ(70px) translateY(-8px);
      overflow: unset;
      img {
        border-radius: 20px;
        padding: 10px;
        box-shadow: 0 0 10px 0px #c7c7c7;
      }
    }

    .market_render_item_brand,
    .market_render_item_name,
    .market_render_item_bottom_group {
      transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform: translateZ(60px) translateY(8px);
    }
  }

  .market_render_item_img_box {
    @include Flex();
    width: 200px;
    height: 200px;
    border-radius: 19px;
    overflow: hidden;
    background-color: $white;
    margin: 5px auto 10px auto;
    position: relative;

    .market_render_item_img_cover {
      width: 200px;
      height: 200px;
      background: url("../../../assets/images/sold_out.png");
      background-color: rgba(0, 0, 0, 0.5);
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      position: absolute;
    }

    img {
      width: 210px;
      height: 210px;
    }
  }

  .market_render_item_brand {
    @include text(15px, 500, $gray900);
    text-align: center;
  }

  .market_render_item_name {
    @include text(14px, 500, $gray900);
    text-align: center;
    margin-top: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 11px;
  }

  .market_render_item_bottom_group {
    @include Flex(space-between);
    padding: 0 20px;
    margin-top: 20px;

    & > span {
      @include text(16px, 500, $invalid);
    }

    & > button {
      @include Flex();
      @include text(13px, 400, $white);
      width: 50px;
      height: 30px;
      text-align: center;
      border-radius: 100px;
      padding: 5px 5px;

      &:disabled {
        color: $gray500;
        background-color: $gray100;
        cursor: inherit;
      }
    }
  }
}
