@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.main_carousel_wrapper {
  margin-bottom: 40px;

  .main_carousel_slider {
    img {
      width: 100px;
      height: 100px;
    }

    @include desktop {
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
}
.swiper-wrapper {
  transition-timing-function: linear;
}
