@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.my_wallet_glare_card_wrapper {
  @include Flex(space-evenly, center, column);
  position: relative;
  width: 280px;
  height: 400px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.6);
  border-top: 1px solid rgba(255, 255, 255, 1);
  border-left: 1px solid rgba(255, 255, 255, 1);
  backdrop-filter: blur(5px);
  overflow: hidden;

  > img {
    width: 220px;
    border-radius: 15px;
    transform: translateY(-20px);
  }

  > div:nth-child(1) {
    @include Flex(center, center);
    position: absolute;
    width: 100%;
    top: 0;
    padding: 10px;
    background-color: $point100;

    > p {
      font-size: 25px;
      font-weight: bold;
      color: $gray650;

      > span {
        font-size: 20px;
        font-weight: normal;
        margin-left: 10px;
        color: $gray650;
      }
    }
  }

  div:nth-child(2) > p {
    font-size: 16px;
    width: 100%;
    color: $gray400;
    margin-top: 40px;
  }

  .glare_card_info {
    @include Flex(flex-start, flex-start, column);
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 400px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #fff;
    transition: ease-in-out 0.3s;
    transform: translateY(400px);
    padding: 20px 30px 0 30px;
    z-index: 99999;

    > .glare_button_box1 {
      width: 100%;

      > button {
        transition: ease-in-out 0.3s;
        background-color: $point500;
        border-radius: 10px;
        padding: 5px 10px;
        color: #fff;

        &:hover {
          transform: scale(105%);
        }
      }

      > .glare_card_check_log_btn {
        width: 50%;
        float: right;
        margin-bottom: 10px;
        background-color: $gray100;
        color: #000;
      }

      > .glare_card_claim_btn {
        width: 120px;
        height: 40px;
        margin: auto;
      }
    }

    > .glare_button_box2 {
      @include Flex(center, center);
      width: 100%;
      padding-top: 20px;

      > button {
        transition: ease-in-out 0.3s;
        border-radius: 10px;
        padding: 5px 10px;
        color: #fff;

        &:hover {
          transform: scale(105%);
        }
      }

      > .glare_card_claim_btn {
        width: 100px;
        height: 40px;
        margin: auto;

        &:nth-child(1) {
          background-color: $point300;
        }
        &:nth-child(2) {
          background-color: $point500;
        }
      }
    }

    .glare_card_info_text_box {
      @include Flex(space-between, center);
      margin-bottom: 10px;
      width: 100%;

      > span:nth-child(1) {
        font-weight: bold;
      }
    }
  }

  &:hover .glare_card_info {
    visibility: visible;
    top: 0;
    transform: translateY(0px);
  }
}

.possible_to_claim_shadow {
  box-shadow: rgba(255, 191, 15, 0.5) 0px 5px 15px;
}

.my_wallet_req_olea_claim_text_wrap {
  @include Flex(space-between, center);
  margin: 10px auto;
  width: 70%;
}

.olea_claim_input {
  border: 1px solid $gray400;
  border-radius: 5px;
  text-align: right;
  padding: 3px 5px;
  margin: 10px 5px 10px 0;
  min-width: 100px;

  &::-webkit-inner-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
}
