@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.nft_info_wrapper {
  @include Flex(space-between, flex-start);
  position: relative;
  width: 100%;
  padding: 40px;

  @include tablet {
    @include Flex(center, center, column);
  }

  .nft_info_nft_box {
    @include Flex(center, center, column);
    position: sticky;
    top: 0;

    img {
      width: 276px;
      height: 276px;
      border-radius: 25px;
      margin-bottom: 15px;
      cursor: pointer;
      transition: all ease 500ms;

      &:hover {
        transform: scale(1.03);
        box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
          0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
          0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
      }
    }

    .nft_info_nft_info_box {
      @include Flex();
      width: 100%;
      height: 36px;

      .nft_info_box_text {
        @include Flex(flex-start);
        font-size: 14px;
        position: relative;
        text-align: start;
        padding-left: 50px;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: #e8e8e8;

        .nft_info_box_text_edition {
          @include Flex();
          font-size: 14px;
          position: absolute;
          right: 0;
          text-align: center;
          border-radius: 10px;
          background-color: #f6f6f6;
          width: 50%;
          height: 100%;
        }
      }
    }
  }

  .nft_info_log_box {
    @include Flex(space-between, center, column);
    position: sticky;
    width: 100%;
    margin-left: 20px;
    min-height: 325px;

    .nft_info_tradelog_wrapper {
      width: 100%;

      .nft_info_tradelog_tabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        li {
          cursor: pointer;
          padding: 8px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border: 1px solid #e1e1e1;
          border-bottom: 0;
          &:hover,
          &.clicked {
            background-color: #ededed;
          }
        }
      }

      .nft_info_tradelog_header {
        padding-top: 6px;
        @include Flex(space-between, center);
        width: 100%;
        border-top: 1px solid #e1e1e1;
        & > span {
          display: block;
          width: 130px;
        }
      }

      .nft_info_tradelog_body {
        @include Flex(space-between, center, column);
        width: 100%;
        font-size: 14px;

        & > div {
          @include Flex(space-between, center);
          width: 100%;
          height: 40px;

          & > span {
            display: block;
            width: 130px;
          }
        }
      }
    }
  }
}

span {
  &.cancel {
    color: #d32f2f;
  }

  &.done {
    color: #179e02;
  }
}
