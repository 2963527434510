@import "../../../../styles/mixin";
@import "../../../../styles/variables";
@import "../../../../styles/mediaQuery";

.main_brand_wrapper {
  @include Flex(center, center);
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding-top: 130px;

  .main_brand_logo_section {
    @include Flex(center, center);
    flex-direction: row;
    margin-bottom: 20px;

    img {
      height: 70px;
    }

    @include desktop {
      img {
        height: 50px;
      }
    }
  }

  .main_bran_carousel_section {
    width: 800px;
    margin-top: 60px;

    @include desktop {
      width: 500px;
    }
  }
}
