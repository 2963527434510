@import "../../../styles/mixin";
@import "../../../styles/variables";

.timer_info_box {
  @include Flex(center, center, column);
  width: 100%;
  height: 40px;
  background-color: #000;
  opacity: 0.5;
  padding: 10px;

  .timer_info_sub_text {
    @include text(15px, 400, $white);
  }
}
