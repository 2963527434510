@import "../../../styles/mixin";
@import "../../../styles/variables";

.drop_down_user_email {
  @include text(20px, 500, $gray700);
  margin-left: 14px;
}

.drop_down_category {
  @include text(15px, 400, $gray600);
  border-bottom: 1px solid $gray100;
  padding: 5px 0 5px 14px;
  cursor: pointer;

  img {
    margin-left: 5px;
  }
}

.drop_down_btn {
  @include Flex(flex-end, center);
  @include textNoColor(14px, 400);
  background-color: $point100;
  color: $gray700;
  padding: 5px 10px;
  cursor: pointer;

  img {
    margin-right: 5px;
  }
}

.drop_down_head {
  color: $gray700;
  @include text(16px, 500, $gray700);

  img {
    padding-right: 5px;
  }
}
