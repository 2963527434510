@import "../../../styles/mixin";
@import "../../../styles/variables";

.sub_header_wrapper {
  @include Flex(flex-start);
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  margin-top: 80px;
  z-index: 20;

  &.holder_bg {
    background-color: $point100;
  }

  &.club_bg {
    @include Flex(flex-start);
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    margin-top: 80px;
    z-index: 20;
    background-color: $clubPoint100;
  }

  &.olea_bg {
    @include Flex(flex-start);
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    margin-top: 80px;
    z-index: 20;
    background-color: #595771;
    color: #fff;
  }

  &.ticket_bg {
    @include Flex(flex-start);
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    margin-top: 80px;
    z-index: 20;
    background-color: $ticketPoint100;
  }

  &.joy_bg {
    background-color: #fdf3cf;
  }

  .sub_header_popper_box {
    position: fixed;
  }

  .sub_header_title_position_box {
    @include Flex(flex-start);
    width: 1128px;
    margin: auto;

    .flex_center {
      @include Flex();
      width: 100%;
    }

    .flex_evenly {
      @include Flex(space-between);
      flex: 1;
    }

    .flex_between {
      @include Flex(space-between);
      flex: 1;

      .joy_nickname {
        color: #b25d24;
        padding-left: 150px;
        font-size: 16px;
      }
    }

    .sub_header_text_row_content {
      font-size: 24px;
      color: $point700;
      margin-left: 20px;
    }

    .olea_sub_header_text_row_content {
      font-size: 24px;
      color: #fff;
      margin-left: 20px;
    }

    .sub_header_text_row_content_blue {
      color: $valid;
    }

    .sub_header_text_row_content_red {
      color: $invalid;
    }

    .sub_header_my_wallet_flex {
      @include Flex(space-between);
      width: 100%;

      & > p {
        @include text(16px, 500, $gray700);

        & > span {
          @include text(14px, 500, $gray500);
        }
      }
    }

    .sub_header_btn_wrapper {
      position: relative;
      margin-right: 40px;
    }

    .sub_header_refresh_nft_btn {
      @include button(130px, 35px, 100px);
      @include Flex(center, center);
      cursor: pointer;
      top: 0;
      right: 0;
      font-size: 14px;
    }

    .sub_header_text_span {
      @include Flex(space-between, center);
      @include text(24px, 500, $gray900);
    }

    .olea_sub_header_text_span {
      @include Flex(space-between, center);
      @include text(24px, 500, #fff);

      > img {
        filter: invert(1);
      }
    }

    .olea_sub_header_text_span {
      @include Flex(space-between, center);
      @include text(24px, 500, #fff);
    }

    .sub_header_purchase_status {
      @include text(18px, 500, $gray700);
    }

    .olea_sub_header_purchase_status {
      @include text(18px, 500, #fff);
    }
  }
}

.sub_header_empty_box {
  width: 100%;
  height: 60px;
}
